<template>
    <el-dialog
        title="立案"
        :visible.sync="casedialogVisible"
        width="30%"
        >
         <el-form
            ref="case_form"
            :model="caseform"
            label-width="100px"
            label-position="left"
            :rules="rules"
        >
          <p style="padding:12px 0;color:#a1a1a1;">商标信息</p>
            <el-form-item label="商标号" prop="reg_num">
              <el-autocomplete
                    style="width:100%"
                    class="inline-input"
                    size="mini"
                    v-model="caseform.reg_num"
                    :disabled='readOnly'
                    :fetch-suggestions="querySearch"
                    placeholder="请输入商标号"
                    :trigger-on-focus="false"
                    @select="handleSelect"
                    clearable
                    @clear="regNum_clear"
                >
                    <template slot-scope="{ item }" >
                        <div class="name" >{{ item.reg_num+ ':' + item.name  }}</div>
                    </template>
              </el-autocomplete>
               <p v-if="Show_exportButton && caseform.reg_num!=''">商标号不在系统中,
              <span style="color:#29c5ee;cursor: pointer;" @click="exportTM">导入 <i class="el-icon-refresh-right"></i></span></p>
            </el-form-item>
            <el-form-item
              label="类别"
              prop="class"
            >
              <el-input v-model="caseform.class" size="mini" clearable :disabled='readOnly'></el-input>
            </el-form-item>
            <el-form-item
              label="商标名称"
            >
              <el-input v-model="caseform.name" size="mini" clearable :disabled='readOnly'></el-input>
            </el-form-item>
            <el-form-item
              label="申请人名称"
            >
            <el-input v-model="caseform.applicant_cn" size="mini" clearable :disabled='readOnly'></el-input>
            </el-form-item>
            <p style="padding:12px 0;color:#a1a1a1; ">案件信息</p>
            <el-form-item
              label="案件类型"
              prop="type"
            >
                <!-- <el-select v-model="caseform.type" 
                  clearable 
                  placeholder="请选择" 
                  size="mini" 
                  :disabled="!caseType_options.length" 
                  @change="change_caseType">
                    <el-option
                    v-for="item in caseType_options"
                    :key="item.case_type"
                    :label="item.case_type"
                    :value="item.case_type">
                    </el-option>
                </el-select> -->
              <el-cascader
                v-model="levelvalue"
                :options="caseType_options"
                :disabled="!caseType_options.length"
                @change="change_caseType"
                :show-all-levels="false"
                collapse-tags
                size="small"
                clearable
              ></el-cascader>
                <span style="font-size:12px;color:#909399">{{case_info}}</span>
            </el-form-item>
            <el-form-item
              label="案件申请日期"
            >
              <el-date-picker
                size="mini"
                v-model="caseform.create_time"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              label="官方绝限"
            >
              <el-date-picker
                size="mini"
                v-model="caseform.app_deadline"
                type="date"
                placeholder="选择日期"
                @change="changeDeadline"
              >
              </el-date-picker>
            </el-form-item>
              <span class="expire" v-if="expire_show">官方绝限已过,请注意核对</span>            
            <el-form-item
              label="对方主体"
            >
              <el-input v-model="caseform.opposite" size="mini" clearable></el-input>
            </el-form-item>
            <el-form-item
              label="我方主体"
            >
                <!-- <el-select v-model="caseform.our_part" clearable placeholder="请选择" size="mini" >
                    <el-option
                    v-for="item in case_our_part"
                    :key="item"
                    :label="item"
                    :value="item">
                    </el-option>
                </el-select> -->
              <el-autocomplete
                class="inline-input"
                ref="our_part"
                clearable
                style="width:100%"
                size="mini"
                v-model="caseform.our_part"
                :fetch-suggestions="our_partQuerySearch"
                placeholder="请输入我方主体"
                @select="our_partHandleSelect"
                @clear="our_partClear"
              >
                    <template slot-scope="{ item }" >
                        <div class="name" >{{ item}}</div>
                    </template>
              </el-autocomplete>
            </el-form-item>
          </el-form>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button size="mini" @click="casedialogVisible = false">取 消</el-button>
            <el-button
              type="primary"
              size="mini"
              @click="add_case"
            >确 定</el-button>
          </span>
    </el-dialog>
</template>

<script>
import {
  Gettrademark,
  Getcases,
  Postcases,
  patchcases,
  Pushtrademark,
} from "../api/axios";
export default {
    name: 'register',
    props: {
      exists:null,
      case_form:null,
      doc_id:null,
      readOnly:null,
    },
    data() {
        return {
            casedialogVisible:true,
            caseform:{
              reg_num:'',
              class:'',
              name:'',
              applicant_cn:'',
              type:'',
              create_time:'',
              app_deadline:'',
              opposite:'',
              our_part:'',
            },
            reg_numData:[],
            caseType_options:[],
            tm_id:'',
            case_our_part:[],
            case_info:'',
            rules: {
              reg_num:[{ required: true, message: '请输入商标号', trigger: 'blur' }],
              class:[{ required: true, message: '请输入类别', trigger: 'blur' }],
              type:[{ required: true, message: '请输入案件类型', trigger: 'change' }]
            },
            Show_exportButton:false,
            levelvalue:[],
            expire_show:false,
            get_odCases:[]
        };
    },

    async mounted() {
        if (this.case_form) {
          if (this.case_form.type) {
            this.levelvalue.push(this.case_form.type)
          }
          console.log(this.case_form);
          //  this.caseform = _.cloneDeep(this.case_form);
          for(let key in this.case_form){
            if (this.case_form[key]) {
              this.caseform[key] = this.case_form[key]
            }
          }
          if (this.caseform.tm_id) {
            this.tm_id = this.caseform.tm_id;
            delete this.caseform.tm_id
            await this.get_case_types();
            await this.change_caseType();
          }
        }
    },

    methods: {
      async querySearch(queryString, cb){
        let obj = {
          reg_num: `${queryString}*`,
        }
        // if (this.exists!=null) {
        //   obj["applicant.id[$exists]"] =this.exists;
        // }
       let { data } = await Gettrademark(obj);
       if (data.length==0) {
        this.Show_exportButton = true;
        for(let key in this.caseform){
          if (key!='reg_num') {
            this.caseform[key] = '';
          }
        }
       }else{
        this.Show_exportButton = false;
       }
      this. reg_numData = data;
      cb(data);
      },
      handleSelect(item){
        console.log(item);
        this.caseform.reg_num =   item.reg_num;
        item.name ?this.caseform.name = _.cloneDeep(item.name) :this.caseform.name = '';
        item.applicant?.applicant_cn ? this.caseform.applicant_cn = item.applicant?.applicant_cn:this.caseform.applicant_cn ='';
        item.class ?this.caseform.class = item.class :this.caseform.class = '';
        this.tm_id = item._id;
        this.get_case_types();
      },
      our_partHandleSelect(item){
        this.caseform.our_part = item;
      },
      async get_case_types(){
        try {
          let obj = {
            $get_candidate_casetypes:1,
            tm_id:this.tm_id,
            }
            if (this.doc_id) {
              obj.doc_id = this.doc_id
            }
            var data =  await Getcases(obj);
            this.get_odCases = data;
            data.forEach(item => {
              if (item.case_type) {
                item.label = item.case_type
                item.value = item.case_type
              }
            });
           let caseArr = this.$store.getters.get_op_tree(2);
           caseArr.forEach(item=>{
            if (item.base_type=='行政诉讼') {
              item.children = _.sortBy(item.children,'value')
            }
           })
          this.caseType_options = _.concat(data,caseArr)
           console.log(this.caseType_options,'查询案件类型');    
        } catch (error) {
            console.log(error);
        }
      },
      regNum_clear(){
        // this.caseform.name = '';
        // this.caseform.applicant_cn = '';
        // this.caseType_options = [];
        // this.caseform.case_type = '';
        // this.caseform.class = '';
        this.caseform = this.$options.data().caseform
      },
      async change_caseType(row){
          this.case_our_part  =[];
          this.caseform.our_part = '';
          if (row) {
            this.caseform.type = row[row.length - 1];
            console.log(this.caseform.type);
          }
        if (this.caseform.type) {
          try {
            let result;
            for (let item of this.caseType_options) {
              if (item.case_type === this.caseform.type) {
                result = item;
                break;
              } else if (item.children && Array.isArray(item.children)) {
                const childMatch = item.children.find(child => child.case_type === this.caseform.type);
                if (childMatch) {
                  result = childMatch;
                  break;
                }
              }
            }
            if (result) {
            let obj = {
                $query_case_info:1,
                tm_id:this.tm_id,
                case_base_type:result.base_type,
                case_type:result.case_type
            }
            if (this.doc_id) {
              obj.od_id = this.doc_id
            }
            let data = await Getcases(obj);
            for(let key in data.case_info){
              if (data.case_info[key] && key !='our_part' && key !='case_type') {
              this.caseform[key] = data.case_info[key] 
              }else{
                if (key !='our_part' && key !='case_type') {
                    this.caseform[key] = '' 
                }
              }
            }
            this.case_info  = data.messages.casetype_message;
            if (data.case_info.our_part) {
             this.case_our_part = data.case_info.our_part; 
            }
            if (this.case_our_part.length==1) {
              this.caseform.our_part = this.case_our_part[0];
            }          
            }else{
              this.caseform.type ='';
            }        
          } catch (error) {
            console.log(error);
          }
        }else{
          this.case_info = '';
        }
        if (this.caseform.app_deadline) {
          this.changeDeadline(new Date(this.caseform.app_deadline));
        }else{
          this.expire_show = false;
        }
      },
      async add_case(){
         this.$refs['case_form'].validate(async (valid) => {
          if (valid) {
            try {
              let odcaseObj = this.get_odCases.find(item=>item.case_type===this.caseform.type);
              var data = await Postcases(this.caseform);
              var obj = {
                tm_id: this.tm_id
              }
              if (this.doc_id && odcaseObj.bind_or_ref === 'binds') {
                obj.doc_id = this.doc_id;
              }
              await patchcases(
                data._id,
                obj,
                { $manual_bind: 1, $call_hooks:true }
              );
              this.casedialogVisible = false;
              this.$message.success('立案成功.')
              this.$emit('getCase');
            } catch (error) {
              console.log(error);
            }            
          }
         })
      },
      //查询商标号不存在导入商标
      // 9479936
      async exportTM(){
        let params = {
          type: "import",
          content: {
            type: "byregnum",
            reg_nums:[this.caseform.reg_num],
            cls_desc: this.caseform.class,
          },
        };
         await Pushtrademark(params, true);
         this.$message.info('正在导入商标请稍等');
          _.delay(() => this.queryTM(this.caseform.reg_num), 5e2);
      },
      //查询商标是否导入系统
      async queryTM(item){
        try {
        let { data } = await Gettrademark({
          reg_num:item
        });
        let  obj = data[0];
        if (obj._id) {
          this.$message.success('已导入商标请继续立案');
          this.Show_exportButton = false;
          this.caseform.name = obj.name;
          obj.applicant?.applicant_cn ? this.caseform.applicant_cn = obj.applicant?.applicant_cn:this.caseform.applicant_cn ='';
          this.caseform.class = obj.class;
          this.tm_id = obj._id;
          this.get_case_types();
        }else{
        }          
        } catch (error) {
         this.$message.error('导入失败请检查商标号是否正确!')
        }
      },
      our_partQuerySearch(queryString, cb){
        var restaurants = this.case_our_part;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
          cb(results);
      },
      createFilter(queryString) {
        return (restaurant) => {
          return (restaurant.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
      changeDeadline(date){
        if (date) {
          date.setHours(0, 0, 0, 0)
          var today = new Date();
          today.setHours(0, 0, 0, 0);
          if (date.getTime() < today.getTime()) {
            this.expire_show = true;
          }else{
            this.expire_show = false;
          }          
        }else{
          this.expire_show = false;
        }
      },
      //解决当选中状态下清空数据下拉框不显示
      our_partClear(){
         this.$refs.our_part.activated = true;
      },
    },
    watch: {
        casedialogVisible(n) {
        if (!n) {
            this.$emit("changecasedialog", n);
        }
        },
    },
};
</script>

<style lang="less" scoped>
 /deep/ .el-dialog .el-dialog__header{
  background: #f3f6f9;
  border-bottom: 1px solid #d7d8d9;
}
/deep/ .el-dialog__body .el-form-item{
  margin-bottom: 12px;
}
.expire{
  margin-left: 100px;
  font-size:12px;
  color:red;
}
/deep/ .el-dialog{
  max-height: unset !important;
}
</style>