<template>
  <!-- 96px -->
  <el-form
  
    :inline="true"
    :model="formModel"
    class="searchboxForm main-content_top"
    label-position="right"
    label-width="108px"

  >
    <div id="container" ref="container">
        <slot name="inputBox"></slot>
        <div class="operateBox">
          <div class="box2" ref="operateBox">
            <el-button size="small" type="primary" class="search" @click="handleSearch">查询</el-button>
            <el-link  :underline="false" class="reset" @click="handleReset" >重置</el-link>
            <slot name="operateBox"></slot>
            <el-link  :underline="false" class="moreQuery" @click="handleChangeShow" v-show="containerBox.length -1 > 3">更多查询 <i :class="moreQueryIconShow?'el-icon-caret-bottom':'el-icon-caret-top'"></i></el-link>
          </div>
        </div>
    </div>
  </el-form>
</template>

<script>
import lodash from 'lodash'
export default {
    name:'SearchBox',
    data(){
      return{
        containerBox:[],
        moreQueryIconShow:true
      }
    },
    props:{
      formModel:{
        type:Object,
        default:{},
      }
    },
    mounted(){
      // 控制搜索框的 搜索条件隐藏
      this.containerBox = this.$refs.container.children
      for (let i = 0; i < this.containerBox.length; i++) {
        if (this.containerBox[i].className.indexOf('operateBox') > -1) break;
          this.containerBox[i].style.display = i > 3?"none":"flex"
      }
      // 控制操作的样式  最后一个不加
      let operateBox = this.$refs.operateBox.children
      for (let i = 0; i < operateBox.length - 1; i++) {
        operateBox[i].style.marginRight = 28 +'px';
      }
    },
    watch:{
      moreQueryIconShow:{
        handler(newVal){
          for (let i = 0; i < this.containerBox.length; i++) {
            if (this.containerBox[i].className.indexOf('operateBox') > -1) break;
            if (newVal) {
              this.containerBox[i].style.display = i > 3?"none":"flex"
            }else{
              this.containerBox[i].style.display = 'flex'
            }
          }
        },
      }
    },
    methods:{
      handleSearch(){
        this.$emit('search');
      },
      handleReset(){
        this.$emit('reset');
      },
      handleChangeShow(){
        this.moreQueryIconShow = !this.moreQueryIconShow
        this.$emit('change_more')
      },
    },
}
</script>

<style lang="less" scoped>
@media (min-width: 1600px) {
  ::v-deep #container{
    grid-template-columns: repeat(5, 1fr);
    .operateBox{
      grid-column: 5;
    }
  }
}
@media (max-width: 1800px) {
  ::v-deep #container{
    grid-template-columns: repeat(3, 1fr);
    .operateBox{
      grid-column: 3;
    }
  }
}
@media (max-width: 1450px) {
  ::v-deep #container{
    grid-template-columns: repeat(2, 1fr);
    .operateBox{
      grid-column: 2;
    }
  }
}

@media (max-width: 850px) {
  ::v-deep #container{
    grid-template-columns: repeat(1, 1fr);
    .operateBox{
      grid-column: 1;
    }
  }
}

.searchboxForm{
  width: 100%;
  padding-bottom: 0;
}
#container{
  width: 100%;
  display: grid;
  grid-gap: 14px;
    ::v-deep .el-form-item{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0 0 14px 0;
      .el-form-item__content{
          width: calc(100% - 108px);
          .el-date-editor,.el-range-editor,.el-input__inner,.el-date-editor--daterange,.el-input,.el-select,.el-autocomplete,.inline-input,.custom-date-picker{
            width:100%;
            max-width: 230px;
          }
      }
    }
    // 只占最后一列，最后一行
  .operateBox{
    grid-row: auto / span 1; /* 自动调整行，占据1个单元格 */
    .box2{
      display: flex;
      // justify-content: center;
      justify-content: flex-end;
      align-items: center;
      margin-top: 6px;
      margin-bottom: 14px;
    }
  }
}
// 单独占一行
.full-row{
  grid-column: 1 / -1;
}
</style>